<template>
  <document-upload
    :front="resend.document.front"
    :back="resend.document.back"
    :is-loading="isLoading"
    @click:continue="onFinish"
    @click:back="onClickBack"
    @change:front="changeResend({ 'document.front': $event })"
    @change:back="changeResend({ 'document.back': $event })"
  />
</template>

<script>
import { useRoute, useRouter } from "vue-router/composables"

import DocumentUpload from "./components/DocumentUpload.vue"
import { useResendDocuments } from "./useResendDocuments"

export default {
  components: { DocumentUpload },
  beforeRouteEnter(to, from, next) {
    if (from.name === null) {
      next({ name: "document-scan", params: to.params })
    } else {
      next()
    }
  },
  setup() {
    const { resend, isLoading, changeResend, onFinish } = useResendDocuments()

    const router = useRouter()
    const route = useRoute()

    const onClickBack = () => {
      router.push({
        name: "document-scan",
        params: route.params,
      })
    }

    return {
      resend,
      isLoading,

      changeResend,
      onFinish,
      onClickBack,
    }
  },
}
</script>

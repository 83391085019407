import { useRoute, useRouter } from "vue-router/composables"
import {
  useNamespacedActions,
  useNamespacedMutations,
  useNamespacedState,
} from "vuex-composition-helpers/dist"

import { useStoreAction } from "@/composables"
import i18n from "@/i18n"

/**
 *
 */
export function useResendDocuments() {
  const { resend } = useNamespacedState("guest/documents", ["resend"])
  const { changeResend } = useNamespacedMutations("guest/documents", [
    "changeResend",
  ])
  const { resendDocuments, isLoading, hasError } = useStoreAction(
    "guest/documents",
    "resendDocuments"
  )
  const { sendNotification } = useNamespacedActions("notifications", [
    "sendNotification",
  ])

  const router = useRouter()
  const route = useRoute()

  const onFinish = async () => {
    await resendDocuments()

    if (hasError.value) {
      sendNotification({
        message: i18n.tc("documentScanner.error_sending_documents"),
        duration: 4000,
        color: "error",
      })
    } else {
      router.push({
        name: "document-scan-success",
        params: route.params,
      })
    }
  }

  return {
    resend,
    changeResend,
    isLoading,
    hasError,

    onFinish,
  }
}

<template>
  <z-card title="Reenviar documento" width="100%">
    <template #content>
      <div class="upload-container">
        <illustration-document-scan></illustration-document-scan>
        <h4 class="body-text text-center">
          {{ $t("reservation.scanner_instruction_title") }}
        </h4>
        <p class="body2 text-center description">
          {{ $t("reservation.scanner_instruction_description") }}
        </p>

        <div v-if="$vuetify.breakpoint.smAndDown" class="divider"></div>

        <uploaded-card
          v-if="front"
          class="ma-0"
          enable-close
          :image-source="'front_document.jpg'"
          :image="front"
          @click:erase="onEraseFront"
        />
        <crop-modal
          v-else-if="memoryImage.front"
          class="crop-modal"
          :opened="!!memoryImage.front"
          :image="{ back: memoryImage.front }"
          @close="memoryImage.front = ''"
          @confirm:image="onConfirmFront"
        />
        <z-btn
          v-else
          primary
          icon="$attach_file_rounded"
          text="Frente do documento"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="onClickFront"
        />
        <input
          id="imageInput"
          ref="imageInputFront"
          type="file"
          style="display: none"
        />

        <uploaded-card
          v-if="back"
          class="ma-0"
          enable-close
          :image-source="'back_document.jpg'"
          :image="back"
          @click:erase="onEraseBack"
        />
        <crop-modal
          v-else-if="memoryImage.back"
          class="crop-modal"
          :opened="!!memoryImage.back"
          :image="{ back: memoryImage.back }"
          @close="memoryImage.back = ''"
          @confirm:image="onConfirmBack"
        />
        <z-btn
          v-else
          primary
          icon="$attach_file_rounded"
          text="Verso do documento"
          :width="$vuetify.breakpoint.smAndDown ? '100%' : ''"
          @click="onClickBack"
        />
        <input
          id="imageInput"
          ref="imageInputBack"
          type="file"
          style="display: none"
        />

        <div class="actions-container" style="width: 100%">
          <z-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            width="130px"
            text="Voltar"
            :disabled="isLoading"
            @click="$emit('click:back')"
          />
          <z-btn
            primary
            text="Enviar"
            :class="{ 'mt-10': $vuetify.breakpoint.smAndDown }"
            :disabled="!front || !back"
            :width="$vuetify.breakpoint.mdAndUp ? '130px' : '100%'"
            :is-loading="isLoading"
            @click="$emit('click:continue')"
          />
          <z-btn
            v-if="$vuetify.breakpoint.smAndDown"
            width="100%"
            text="Voltar"
            :disabled="isLoading"
            @click="$emit('click:back')"
          />
        </div>
      </div>
    </template>
  </z-card>
</template>

<script>
import { reactive, ref } from "vue"

import { readFile } from "@/composables/useParser"

export default {
  props: {
    front: {
      type: [Object, String],
      default: "",
    },
    back: {
      type: [Object, String],
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const imageInputFront = ref(null)
    const imageInputBack = ref(null)

    const memoryImage = reactive({
      front: "",
      back: "",
    })

    // Front
    const onClickFront = () => {
      imageInputFront.value.click()
      imageInputFront.value.onchange = async (e) => {
        let fileFront = e.target.files[0]
        if (!fileFront) return

        memoryImage.front = await readFile(fileFront)
      }
    }

    const onConfirmFront = (croppedImage) => {
      emit("change:front", croppedImage)
      memoryImage.front = ""
    }

    const onEraseFront = () => {
      memoryImage.front = ""
      emit("change:front", "")
    }

    // Back
    const onClickBack = () => {
      imageInputBack.value.click()
      imageInputBack.value.onchange = async (e) => {
        let fileBack = e.target.files[0]
        if (!fileBack) return

        memoryImage.back = await readFile(fileBack)
      }
    }

    const onConfirmBack = (croppedImage) => {
      emit("change:back", croppedImage)
      memoryImage.back = ""
    }

    const onEraseBack = () => {
      memoryImage.back = ""
      emit("change:back", "")
    }

    return {
      memoryImage,

      // front
      imageInputFront,
      onClickFront,
      onConfirmFront,
      onEraseFront,

      // back
      imageInputBack,
      onClickBack,
      onConfirmBack,
      onEraseBack,
    }
  },
}
</script>

<style lang="scss" scoped>
h4 {
  max-width: 500px;
}

.upload-container {
  display: grid;
  justify-items: center;
  margin-top: var(--core-spacing-lg);
  row-gap: var(--core-spacing-md);
}

.divider {
  background-color: var(--black-300);
  width: 100%;
  height: 1px;
  max-width: 700px;
}

.description {
  max-width: 390px;
}

.actions-container {
  display: grid;
  row-gap: var(--core-spacing-md);
}

::v-deep .document-scanner-crop {
  position: fixed !important;
  z-index: 4;
}

@media screen and (min-width: 786px) {
  ::v-deep .document-scanner-crop {
    position: unset !important;
    z-index: 4;
  }

  .actions-container {
    grid-template-columns: min-content min-content;
    column-gap: var(--core-spacing-md);
    justify-content: center;
    margin-top: var(--core-spacing-lg);
  }
}
</style>
